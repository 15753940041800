// import React, { useState, useEffect } from 'react';
// import axios from 'axios';
// import Button from 'react-bootstrap/Button';
// import Modal from 'react-bootstrap/Modal';
// import Table from 'react-bootstrap/Table';

// function Modeldata() {
//   const values = [true, ];
//   const [fullscreen, setFullscreen] = useState(true);
//   const [show, setShow] = useState(false);
//   const [apiData, setApiData] = useState([]); // State to store API data

//   useEffect(() => {
//     // Fetch data from API
//     axios.get('https://unmaze-backend-dev.delightfuldesert-ec0a4653.eastasia.azurecontainerapps.io/all')
//       .then(response => {
//         setApiData(response.data);
//       })
//       .catch(error => console.error("There was an error fetching the data: ", error));
//   }, []);

//   function handleShow(breakpoint) {
//     setFullscreen(breakpoint);
//     setShow(true);
//   }

//   return (
//     <>
//       {values.map((v, idx) => (
//         <Button key={idx} className="me-2 mb-2" onClick={() => handleShow(v)}>
//           Full screen
//           {typeof v === 'string' && `below ${v.split('-')[0]}`}
//         </Button>
//       ))}
//       <Modal show={show} fullscreen={fullscreen} onHide={() => setShow(false)}>
//         <Modal.Header closeButton>
//           <Modal.Title>Employee Details</Modal.Title>
//         </Modal.Header>
//         <Modal.Body>
//           <Table striped bordered hover>
//             <thead>
//               <tr>
//                 <th>Employee ID</th>
//                 <th>Name</th>
//                 <th>Email</th>
//                 <th>Phone</th>
//                 <th>Checked In</th>
//               </tr>
//             </thead>
//             <tbody>
//               {apiData.map((employee, index) => (
//                 <tr key={index}>
//                   <td>{employee.employeeid}</td>
//                   <td>{`${employee.firstname} ${employee.lastname}`}</td>
//                   <td>{employee.emailid}</td>
//                   <td>{employee.phonenumber}</td>
//                   <td>{employee.ischeckedin ? 'Yes' : 'No'}</td>
//                 </tr>
//               ))}
//             </tbody>
//           </Table>
//         </Modal.Body>
//       </Modal>
//     </>
//   );
// }

// export default Modeldata;


import React, { useState, useEffect } from 'react';
import axios from 'axios';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import Table from 'react-bootstrap/Table';

function Modeldata() {
  const values = [true, ];
  const [fullscreen, setFullscreen] = useState(true);
  const [show, setShow] = useState(false);
  const [apiData, setApiData] = useState([]); // State to store API data

  useEffect(() => {
    axios.get('https://unmaze-backend-dev.delightfuldesert-ec0a4653.eastasia.azurecontainerapps.io/all')
      .then(response => {
        setApiData(response.data);
      })
      .catch(error => console.error("There was an error fetching the data: ", error));
  }, []);

  function handleShow(breakpoint) {
    setFullscreen(breakpoint);
    setShow(true);
  }

  // Filter data based on ischeckedin status
  const checkedInData = apiData.filter(employee => employee.ischeckedin);
  const notCheckedInData = apiData.filter(employee => !employee.ischeckedin);

  // Render Table Rows
  const renderTableRows = (data) => {
    return data.map((employee, index) => (
      <tr key={index}>
        <td>{employee.employeeid}</td>
        <td>{`${employee.firstname} ${employee.lastname}`}</td>
        <td>{employee.emailid}</td>
        <td>{employee.phonenumber}</td>
      </tr>
    ));
  };

  return (
    <>
      {values.map((v, idx) => (
        <Button key={idx} className="me-2 mb-2" onClick={() => handleShow(v)}>
          Full screen
          {typeof v === 'string' && `below ${v.split('-')[0]}`}
        </Button>
      ))}
      <Modal show={show} fullscreen={fullscreen} onHide={() => setShow(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Employee Details</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <h5>Checked In</h5>
          <Table striped bordered hover>
            <thead>
              <tr>
                <th>Employee ID</th>
                <th>Name</th>
                <th>Email</th>
                <th>Phone</th>
              </tr>
            </thead>
            <tbody>
              {renderTableRows(checkedInData)}
            </tbody>
          </Table>

          <h5>Not Checked In</h5>
          <Table striped bordered hover>
            <thead>
              <tr>
                <th>Employee ID</th>
                <th>Name</th>
                <th>Email</th>
                <th>Phone</th>
              </tr>
            </thead>
            <tbody>
              {renderTableRows(notCheckedInData)}
            </tbody>
          </Table>
        </Modal.Body>
      </Modal>
    </>
  );
}

export default Modeldata;

