import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import {
  MDBBtn,
  MDBContainer,
  MDBRow,
  MDBCol,
  MDBInput,
} from "mdb-react-ui-kit";

function Login(props) {
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [usernameError, setUsernameError] = useState("");
  const [passwordError, setPasswordError] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  const navigate = useNavigate();

  const containerStyle = {
    height: "100vh",
    overflowY: "hidden",
  };

  const handleLogin = async () => {
    if (!username || !password) {
      toast.error("Please fill in both username and password fields");
      return;
    }

    try {
      const response = await fetch(
        "https://unmaze-backend-dev.delightfuldesert-ec0a4653.eastasia.azurecontainerapps.io/login",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
          },
          body: JSON.stringify({
            username,
            password,
          }),
        }
      );

      if (username === "UnMazeAdmin" && password === "U39?m3Om4pK1") {
        setIsLoading(true);

        toast.success('Logged in successfully');
        
        navigate("/map");
      } else {
        toast.error("Invalid username or password");
      }
    } catch (error) {
      console.error("Error occurred:", error);
    } finally {
    }
  };

  const handleUsernameBlur = () => {
    if (!username) {
      setUsernameError("Please fill in the username ");
    } else {
      setUsernameError("");
    }
  };

  const handlePasswordBlur = () => {
    if (!password) {
      setPasswordError("Please fill in the password ");
    } else {
      setPasswordError("");
    }
  };

  return (
    <MDBContainer className="my-5 gradient-form w-full" style={containerStyle}>
      <MDBRow>
        <MDBCol col="6" className="mb-5">
          <div className="logo-header">
            <img src={require("../images/white-logo.png")} alt="" />
          </div>
          <div className="d-flex flex-column ms-5">
            <div className="text-center">
              <img
                src={require("../images/white-logo.png")}
                style={{ width: "2px" }}
                alt="logo"
              />
              <h4 className="mt-1 mb-5 pb-1">We are The Evolutyz Team</h4>
            </div>

            <p>Please login to your account</p>
            {usernameError && (
              <div className="text-danger">{usernameError}</div>
            )}
            <MDBInput
              wrapperClass="mb-4"
              label="User Name"
              id="form1"
              type="email"
              onBlur={handleUsernameBlur}
              value={username}
              required
              onChange={(e) => setUsername(e.target.value)}
              error={usernameError}
            />

            {passwordError && (
              <div className="text-danger">{passwordError}</div>
            )}
            <MDBInput
              wrapperClass="mb-4"
              label="Password"
              id="form2"
              type="password"
              required
              onBlur={handlePasswordBlur}
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              error={passwordError}
            />

            {/* {error && <div className="text-danger">{error}</div>} */}

            <div className="text-center pt-1 mb-5 pb-2">
              <button
                onClick={handleLogin}
                className="mb-4 w-50 h-4 gradient-custom-2"
              >
                Sign in
              </button>
            </div>
          </div>
        </MDBCol>

        <MDBCol col="6" className="mb-5">
          <div className="d-flex flex-column  justify-content-center gradient-custom-2 h-100 mb-4">
            <div className="text-white px-3 py-4 p-md-5 mx-md-4">
              <h4 class="mb-4">We are more than just a company</h4>
              <p class="small mb-0">
                Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do
                eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut
                enim ad minim veniam, quis nostrud exercitation ullamco laboris
                nisi ut aliquip ex ea commodo consequat.
              </p>
            </div>
          </div>
        </MDBCol>
      </MDBRow>
      {/* {isLoading && <div>Loading...</div>} */}

      <ToastContainer
        position="top-right"
        autoClose={3000}
        hideProgressBar={false}
        newestOnTop
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
    </MDBContainer>
  );
}

export default Login;
