import React, { useState, useEffect } from "react";
import Listview from "./listview";
import GMap from "../components/GMap";
import GoogleMapReact from "google-map-react";
import Marker from "./Marker";
import { Navigate, useNavigate } from "react-router-dom";
import Button from "react-bootstrap/Button";
import Preloader from "./Preloader";

import axios from "axios";
import Modal from "react-bootstrap/Modal";
import Table from "react-bootstrap/Table";

const GOOGLE_MAP_API_KEY = "AIzaSyC4Z5Qz97EWcoCczNn2IcYvaYG0L9pe6Rk";

const RESORT_CO_ORDINATES = { lat: 17.707683168437704, lng: 83.31247751427206 };

const OFFICE_CO_ORDINATES = { lat: 17.739349299477045, lng: 83.31304490400785 };

const loadGoogleMapScript = (callback) => {
  if (
    typeof window.google === "object" &&
    typeof window.google.maps === "object"
  ) {
    callback();
  } else {
    const googleMapScript = document.createElement("script");
    googleMapScript.src = `https://maps.googleapis.com/maps/api/js?key=${"AIzaSyC4Z5Qz97EWcoCczNn2IcYvaYG0L9pe6Rk"}`;
    window.document.body.appendChild(googleMapScript);
    googleMapScript.addEventListener("load", callback);
  }
};

function Map() {
  const [loadMap, setLoadMap] = useState(false);
  const [checkedbox, setChecked] = useState(true);
  const [loading, setLoading] = useState(true);
  const [select, setSelected] = useState("");
  const [optionList, setOptionList] = useState([]);
  const [setChannel, checkChannel] = useState(false);
  const navigate = useNavigate();
  const [showModal, setShowModal] = useState(false);

  const handleLogout = () => {
    navigate("/");
  };

  const checkChange = (e) => {
    console.log(e);
    setChecked((prevState) => !prevState);
  };
  const channelChange = (b) => {
    //console.log(b);
    if (b) {
      document.getElementById("switchValue").style.display = "inline-block";
    } else {
      document.getElementById("switchValue").style.display = "none";
    }
    checkChannel((prevState) => !prevState);
  };
  const selectChange = (v) => {
    if (v !== "0") {
      let bi = v.substring(0, v.length - 3).replace("_", " ");
      console.log(bi);
      var z = function z(str) {
        return str
          .toLowerCase()
          .replace(/[^a-zA-Z]+(.)/g, (m, cha) => cha.toUpperCase());
      };
      document.getElementById("checkSwitch").style.display = "flex";
      document.getElementById("switchValue").innerText = z(bi);
      setSelected(v);
    } else {
      document.getElementById("checkSwitch").style.display = "none";
      document.getElementById("switchValue").innerText = "";
    }
  };

  const [markerList, setmarkerList] = useState([]);

  const fetchPlaces = async () => {
    console.log("REFESH PLACES......");
    fetch(
      // "https://unmaze.blackmeadow-86f5e8cd.eastasia.azurecontainerapps.io/all"
      "https://unmaze-backend-dev.delightfuldesert-ec0a4653.eastasia.azurecontainerapps.io/all"
    )
      .then((response) => response.json())
      .then((data) => {
        const result = data.filter((dt) => dt.latitude != null);

        result.forEach((object) => {
          object.lat = object.latitude;
          object.lng = object.longitude;
        });

        console.log("RES:", result);
        setmarkerList(result);
      });
  };







  const [fullscreen, setFullscreen] = useState(true);
  const [apiData, setApiData] = useState([]); // State to store API data

  useEffect(() => {
    // Fetch data from API
    axios
      .get(
        "https://unmaze-backend-dev.delightfuldesert-ec0a4653.eastasia.azurecontainerapps.io/all"
      )
      .then((response) => {
        setApiData(response.data);
      })
      .catch((error) =>
        console.error("There was an error fetching the data: ", error)
      );
  }, []);

  // Filter data based on ischeckedin status
  const checkedInData = apiData.filter((employee) => employee.ischeckedin);
  const notCheckedInData = apiData.filter((employee) => !employee.ischeckedin);

  // Render Table Rows
  const renderTableRows = (data) => {
    return data.map((employee, index) => (
      <tr key={index}>
        <td>{employee.employeeid}</td>
        <td>{`${employee.firstname} ${employee.lastname}`}</td>
        <td>{employee.emailid}</td>
        <td>{employee.phonenumber}</td>
      </tr>
    ));
  };



  const toggleModal = () => {
    setShowModal(!showModal);
  };

  const fetchData = async () => {
    fetch(
      // "https://unmaze.blackmeadow-86f5e8cd.eastasia.azurecontainerapps.io/allcode"
      "https://unmaze-backend-dev.delightfuldesert-ec0a4653.eastasia.azurecontainerapps.io/allcode"
    )
      .then((response) => response.json())
      .then((data) => {
        const result = data;

        console.log("RES:", result);
        setOptionList(result);
      });
  };

  useEffect(() => {
    fetchData();
  }, []);

  useEffect(() => {
    setTimeout(() => {
      fetchPlaces();
    }, 30000);
  });

  useEffect(() => {
    loadGoogleMapScript(() => {
      setLoadMap(true);
    });
  }, []);

  useEffect(() => {
    const timeout = setTimeout(() => {
      setLoading(false);
    }, 5000); // 5 seconds

    return () => clearTimeout(timeout);
  }, []);

  if (!markerList || markerList.length === 0) {
    return null;
  }

  
 

  return (
    <div>
      {loading ? (
        <><Preloader /><p> Loding</p></>
      ) : (
        <div className="map">
          <div className="logo-header">
            <img src={require("../images/white-logo.png")} alt="" />
            <img src={require("../images/Logo without tagline.png")} alt="" />
          </div>
          <div className="align-flex">
            {checkedbox ? (
              <>
                <h2>Employee Map Status</h2>
                
              </>
            ) : (
              <>
                <div className="channel">
                  <h2>Employee Activity Status : </h2>
                  <select
                    onChange={(e) => {
                      selectChange(e.target.value);
                    }}
                  >
                    <option value="0">-- select channel--</option>
                    {optionList.map((option) => (
                      <option key={option.id} value={option.channelname}>
                        {option.channelname}
                      </option>
                    ))}
                  </select>

                 
                  <div
                    className="toggle-btn"
                    id="checkSwitch"
                    style={{ display: "none" }}
                  >
                    <div class="switch">
                      <input
                        type="checkbox"
                        name=""
                        onChange={(e) => {
                          channelChange(e.target.checked);
                        }}
                        checked={setChannel}
                      />
                      <span></span>
                      
                    </div>
                    <label
                      id="switchValue"
                      style={{ display: "none", marginLeft: 10 }}
                    ></label>
                  </div>
                  <Button onClick={toggleModal} style={{ marginLeft: "10px",  backgroundColor: "#ff6600",  borderColor: "#FFA500" }}>
                    CheckedIn Report
                  </Button>

                  <Modal
                    show={showModal}
                    fullscreen={fullscreen}
                    onHide={() => setShowModal(false)}
                  >
                    <Modal.Header closeButton>
                      <Modal.Title>Employee Details</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                      <h5>Checked In</h5>
                      <Table striped bordered hover>
                        <thead>
                          <tr>
                            <th>Employee ID</th>
                            <th>Name</th>
                            <th>Email</th>
                            <th>Phone</th>
                          </tr>
                        </thead>
                        <tbody>{renderTableRows(checkedInData)}</tbody>
                      </Table>

                      <h5>Not Checked In</h5>
                      <Table striped bordered hover>
                        <thead>
                          <tr>
                            <th>Employee ID</th>
                            <th>Name</th>
                            <th>Email</th>
                            <th>Phone</th>
                          </tr>
                        </thead>
                        <tbody>{renderTableRows(notCheckedInData)}</tbody>
                      </Table>
                    </Modal.Body>
                  </Modal>
                </div>
              </>
            )}

            <div className="toggle-btn">
              <label>{checkedbox ? "Grid" : "Map"} </label>
              <div className="switch">
                <input
                  type="checkbox"
                  onChange={(e) => {
                    checkChange(e.target.checked);
                  }}
                  checked={checkedbox}
                />
                <span></span>
              </div>
            </div>
            <Button
              onClick={handleLogout}
              style={{
                backgroundColor: "#ff6600",
                borderColor: "#FFA500",
              }}
            >
              Logout
            </Button>
          </div>

          {checkedbox ? (
            <>
              {!loadMap ? (
                <div>Loading...</div>
              ) : (
                <div className="googleMap">
                  <GoogleMapReact
                    bootstrapURLKeys={{
                      key: "AIzaSyC4Z5Qz97EWcoCczNn2IcYvaYG0L9pe6Rk",
                      language: "en",
                    }}
                    defaultCenter={RESORT_CO_ORDINATES}
                    center={RESORT_CO_ORDINATES}
                    zoom={15}
                    yesIWantToUseGoogleMapApiInternals
                    onGoogleApiLoaded={({ map, maps }) =>
                      new maps.Circle({
                        strokeColor: "#000",
                        strokeOpacity: 0.4,
                        strokeWeight: 2,
                        fillColor: "#000",
                        fillOpacity: 0.15,
                        map,
                        center: {
                          lat: RESORT_CO_ORDINATES.lat,
                          lng: RESORT_CO_ORDINATES.lng,
                        },
                        radius: 1000,
                      })
                    }
                  >
                    {markerList.map((place) => (
                      <Marker
                        key={place.id}
                        // text={place.name}
                        lat={place.lat}
                        lng={place.lng}
                        empid={place.employeeid}
                        dta={place}
                      />
                    ))}
                  </GoogleMapReact>
                </div>
              )}
            </>
          ) : (
            <Listview selectValue={select} switchValue={setChannel} />
          )}
        </div>
      )}
    </div>
  );
}

export default Map;

